@font-face {
  font-family: "Goldplay Medium";
  src: url("./Goldplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Goldplay Bold";
  src: url("./Goldplay-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
